import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '../../../environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationServicesService {
  apiUrl = `${baseUrl}/api/`;
  isAuth: boolean = false;

  constructor(private http: HttpClient, private router: Router) {}
  setUserToken(token: any) {
    localStorage.setItem('token', token);
    this.isAuth = true;
    this.checkUserAuth();
  }
  setUserObj(userObj: any) {
    localStorage.setItem('userData', JSON.stringify(userObj));
    localStorage.setItem("branch_id" , userObj?.default_customer_address?.branch_id )
  }
  // setUserLocations(locations: any) {
  //   localStorage.setItem('locations', JSON.stringify(locations));
  // }
  // getUserLocations() {
  //   return JSON.parse(localStorage.getItem('locations') ?? '');
  // }
  getDefaultAddress() {
    return this.http.get(this.apiUrl + 'customerAddress/getDefaultCustomerAddress');
  }

  getAllAddresess(){
    let params = new HttpParams();
    params = params.append("related_objects[]", "customer_addresses");
    return this.http.get(this.apiUrl + 'customer/getCustomerInfo',{params});
  }
  
  changeDefaultAddress(body : any){
    return this.http.post(this.apiUrl + 'customerAddress/changeDefaultCustomerAddress',body);
  }

  checkUserAuth() {
    if (this.getUserToken()) {
      this.isAuth = true;
      const userObj: any = this.getUserObj();
    }
  }
  signOut(redirectToLogin = true) {
    localStorage.clear();
    this.isAuth = false;
    if (redirectToLogin) {
      this.router.navigateByUrl('/');
    }
  }
  getUserToken() {
    return localStorage.getItem('token');
  }
  getUserObj() {
    if (localStorage.getItem('userData')) {
      return JSON.parse(localStorage.getItem('userData') || '');
    }
    return {};
  }
  isUserAuth() {
    return this.isAuth;
  }

  getRestaurantBySubdomain(subdomain: object) {
    return this.http.post(this.apiUrl + 'getRestaurantBySubdomain', subdomain);
  }

  confirmOtp(otpNumber: object) {
    return this.http.post(this.apiUrl + 'auth/verifyPhoneOTP', otpNumber);
  }
  resendOTP(phone: object) { // not working (old API) 
    return this.http.post(this.apiUrl + 'auth/resendPhoneOTP', phone);
  }
  createNewAccount(userData: object) {
    return this.http.post(this.apiUrl + 'auth/register', userData);
  }
  signIn(userData: object) {
    return this.http.post(this.apiUrl + 'auth/login', userData);
  }
  changePassword(newData: object) { // not working (not finished)
    return this.http.post(this.apiUrl + 'user/changePassword', newData);
  }
  createCustomerProfile(userData: object) {
    return this.http.post(this.apiUrl + 'user/createCustomerProfile', userData);
  }
  getCustomerInfo(){
    return this.http.get(this.apiUrl + 'customer/getCustomerInfo?related_objects[0]=users&related_objects[1]=restaurants&related_objects[2]=customer_addresses');
  }
}
